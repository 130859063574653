import React from 'react'

// modules
import parkSvgUrl from '../../assets/park.svg'

// styles
import styles from './_.module.scss'

export default () => (
  <figure className={styles.figure}>
    <img src={parkSvgUrl} alt="This page does not exist" />
  </figure>
)
