import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// modules
import * as selectors from '@modules/redux/selectors'
import { validateAccount } from './modules/helpers'
import { DEFAULT_TEXT, APFM_TEXT } from './modules/constants'

// hooks
import useIsImpersonator from '@hooks/useIsImpersonator'

const ForbiddenMessage = props => {
  const account = props.profile.name
  const isApfmAccount = validateAccount(account)
  const isImpersonator = useIsImpersonator()
  const forbiddenText = isApfmAccount || isImpersonator ?  APFM_TEXT : DEFAULT_TEXT
      
  return (
    <div>
      <h1 className="mab-2 co-secondary-4">{forbiddenText.title}</h1>
      <p className="mab-2 co-gray-3">{forbiddenText.subtitle}</p>
    </div>
  )
}

ForbiddenMessage.propTypes = {
  profile: object
}

ForbiddenMessage.defaultProps = {
  profile: {}
}

const mapStateToProps = createStructuredSelector({
  profile: selectors.getProfile()
})

export default connect(mapStateToProps)(ForbiddenMessage)
